export default (inputString) => {
    if (null === inputString
        || '' === inputString
        || 'undefined' === typeof inputString
    ) {
        return '';
    }

    if ('number' === typeof inputString) {
        return String(inputString);
    }

    const charMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
    };

    return String(inputString).replace(/[&<>'"]/g, (c) => charMap[c]);
};
